let envNetworks = {};

if (process.env.NODE_ENV !== "production") {
  const envConfig = require(`./environments/${process.env.NODE_ENV}.js`);
  envNetworks = envConfig.networks;
}

export const networks = {
  mainnet: {
    lcdURL: "https://lcd.terra.dev",
    chainID: "columbus-5",
    factoryContractAddress: "terra1d75e7mp0wyvfhpuzvc6rwhskyk5gsecn0a5x4q", // Fill in with your project's factory contract
    gasPrices: { uusd: 0.15 },
    allowedPairContracts: [
      "terra152wlyd53nfcltuwcje9ale8qv3hpyna4sv9mvg", // Fill in with your project's pair contract(s)
    ],
  },
  testnet: {
    lcdURL: "https://bombay-lcd.terra.dev",
    chainID: "bombay-12",
    factoryContractAddress: "terra184w0z7psd82arcxffl9c69nkemp3n4rhttjac3",
    gasPrices: { uusd: 0.15 },
    allowedPairContracts: [
      "terra18z0zcepf7q64vkqljzh99pfxvdauv76hcl3a32", // TESTA
    ],
  },
  ...envNetworks,
};

export const defaultNetwork = networks[process.env.REACT_APP_DEFAULT_NETWORK];
